@use "../../assets/style/index" as *;

.subHeader {
  width: 99.8%;
  display: flex;
  height: 5vh;
  border: 1px solid $border-stroke-color;
  position: relative;
  top: -1px;
  min-height: 50px;
  .leadMenu {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left,
    .right {
      width: 50%;
      display: flex;
      align-items: center;
    }

    .left {
      button {
        margin-left: 30px;
      }

      button:last-child {
        margin-left: 15px;
      }
    }

    .right {
      justify-content: flex-end;

      button {
        margin-right: 30px;
      }
      button:first-child {
        margin-right: 15px;
      }
    }
  }

  .formMenu {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left,
    .right {
      width: 50%;
      display: flex;
      align-items: center;
    }

    .left {
      button {
        margin-left: 30px;
      }

      button:last-child {
        margin-left: 15px;
      }
    }

    .right {
      justify-content: flex-end;

      button {
        margin-right: 30px;
      }
      button:first-child {
        margin-right: 15px;
      }
    }

    h1 {
      margin-left: 30px;
      font-weight: 600;
      color: $primary-color;
    }
  }

  .leadDetailMenu {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left,
    .right {
      width: 50%;
      display: flex;
      align-items: center;
    }

    .left {
      button {
        margin-left: 30px;
      }

      button:last-child {
        margin-left: 15px;
      }
    }

    .right {
      justify-content: flex-end;

      button {
        margin-right: 30px;
      }
      button:first-child {
        margin-right: 15px;
      }
    }
  }
}

.filterButton {
  width: 30px;
  height: 30px;
  border: 1px solid $stroke-button;
  border-radius: 5px;
  background: transparent;
  color: $button-color;
  path {
    stroke: $secondary-color;
  }
}

.allButton {
  padding: 0 10px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid $stroke-button;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  color: $button-color;
  svg {
    margin-left: 5px;
  }
}

.standart {
  padding: 0 10px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid $stroke-button;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  color: $button-color;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
}

.newButton {
  padding: 0 10px;
  cursor: pointer;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid $stroke-primary-color;
  border-radius: 5px;
  font-size: 14px;
  background: $secondary-color;
  color: white;
  // margin-right: 25px !important;
  svg {
    margin-left: 3rem;
  }
}

.processButton {
  padding: 0 10px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid $stroke-button;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  color: $button-color;
  display: flex;
  justify-content: center;
  margin-right: 2rem !important;
  svg {
    margin-left: 5px;
  }
}
.leftButton,
.rightButton {
  background: transparent;
  margin-left: 1rem;
}

.leadCard {
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: $primary-color;

  .backButton {
    padding: 8px 20px;
    background-color: transparent;
    cursor: pointer;
    border-radius: 6px;
    & svg {
      stroke-width: 2;
      background-color: transparent;
    }

    &:hover,
    &:hover svg {
      background-color: rgb(243, 243, 243);
    }
  }

  .avatar {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary-color;
    border-radius: 5px;
    color: white;
    margin: 0 20px;
  }

  h1 {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 500;
  }

  h3 {
    font-size: 18px;
    margin-left: 15px;
  }
}
.buttonRight {
  background: transparent;
  color: rgb(197, 194, 194);
  padding: 0.4rem 1rem;
  border-radius: 3px;
  border: 1px solid rgb(197, 194, 194);
  cursor: pointer;
}

.checkboxContact {
  position: relative;
  align-items: center;
  z-index: 50;
  margin-right: 3rem;
  button {
    width: 100%;
    padding: 0 20px;
    height: 30px;
    border: 1px solid $stroke-button;
    border-radius: 5px;
    font-size: 14px;
    background: transparent;
    color: $button-color;
    margin-left: 2rem !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgb(240, 240, 240);
    }
  }
  .select {
    width: 100%;
    padding: 10px 10px;
    position: absolute;
    margin-left: 2rem;
    background-color: white;
    border: 1px solid $stroke-button;
    margin-top: 0.2rem;
    border-radius: 3px;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: pointer;

    label {
      display: flex;
      align-items: center;
      padding: 5px 0;
      cursor: pointer;
      h1 {
        color: $button-color;
        font-size: 14px;
      }
      input {
        width: 25px;
        color: $button-color;
      }
    }
  }
}
