@use "../../../assets/style/index.scss" as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .errorText {
    width: 100%;
    padding: 15px 20px;
    background-color: rgb(184, 100, 100);
    border-radius: 5px;
    color: #f5f5f5;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 20px auto;
  }

  .button {
    background-color: #daa335;
    border: 2px solid #daa370;
    color: white;
    font-size: 15px;
    margin-left: 145px;
    padding: 12px 30px;
    border-radius: 5px;
    margin-top: 50px;
    cursor: pointer;

    &:hover {
      background-color: #daa335f1;
    }
  }
}
