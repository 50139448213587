// .root {
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
// }

// $blue_theme: #70a1ff;
// //Root Container
// .root-container {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }

// .box-controller {
//   visibility: visible;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   max-width: 24em;
//   min-width: 21em;
//   height: 35px;
//   -webkit-box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
//   box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
//   margin-bottom: 30px;
//   align-items: center;
//   transition: visibility 0.5s ease-out;
// }

// .controller {
//   flex: 1;
//   text-align: center;
//   height: 100%;
//   line-height: 2;
//   cursor: pointer;
// }

// //Currently Selected Controller
// .selectedcontroller {
//   transition: border 0.5s ease-out;
//   border-bottom: 2px solid $blue_theme;
// }

// .boxcontainer {
//   display: flex;
//   flex-direction: column;
//   max-width: 24em;
//   min-width: 21em;
//   box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
//   border-radius: 6px;
//   padding: 24px;
// }

// .innerContainer.show {
//   visibility: visible;
// }

// //Header
// .header {
//   text-align: center;
//   padding: 5px;
//   margin-bottom: 17px;
//   /* margin-left: 35px; */
//   /* margin-right: 35px; */
//   font-family: Ozxgen, sans-serif;
//   font-size: 22px;
//   border-bottom: 2px solid $blue_theme;
// }

// .box {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// }

// .inputGroup {
//   display: flex;
//   flex-direction: column;
//   margin-top: 5px;
//   margin-bottom: 5px;
// }

// //Label
// .login-label {
//   font-family: Oxygen, sans-serif;
//   font-size: 20px;
//   font-weight: 600;
//   padding-left: 5px;
// }

// //Input
// .loginInput {
//   height: 2.1em;
//   border-radius: 3px;
//   border: 1px solid $blue_theme;
//   transition: border 0.4s ease-out;
//   padding: 13px;
//   font-family: Oxygen, sans-serif;
//   font-size: 19px;
//   color: #3e3e42;
//   background: rgba(15, 15, 15, 0.01);
// }

// .login-input:hover {
//   border: 2px solid $blue_theme;
// }

// .login-input:focus {
//   border: 2px solid #6565e7;
//   box-shadow: 0px 0px 20px rgba(15, 15, 15, 0.2);
// }

// //Input Placeholder
// .login-input::placeholder {
//   font-family: Oxygen, sans-serif;
//   font-size: 16px; //color: rgba(46, 213, 116, 0.839);
//   color: rgba(15, 15, 15, 0.4);
// }

// .loginBtn {
//   padding: 2px 30px;
//   /* background-color: #417cef; */
//   border: 0;
//   font-size: 18px;
//   border-radius: 3px;
//   font-family: Oxygen, sans-serif;
//   background-color: rgba(15, 15, 15, 0.03);
//   margin-top: 20px;
//   border: 2px solid #417cef;
//   transition: background-color 0.3s ease-out;
//   cursor: pointer;
// }

// .login-btn:hover,
// .login-btn:focus {
//   background-color: #417cef;
//   color: white;
// }

// .danger-error {
//   color: #e74c3c;
//   font-size: 16px;
// }

// //Password
// .password-state {
//   display: flex;
//   flex-direction: row;
//   margin-top: 20px;
// }

// .pwd {
//   height: 6px;
//   flex: 1;
//   visibility: hidden;
// }

// .pwd-weak {
//   background-color: #e74c3c;
// }

// .pwd-medium {
//   background-color: #3498db;
// }

// .pwd-strong {
//   background-color: #2ecc71;
// }

// .show {
//   visibility: visible;
// }
.innerContainer {
  width: 100%;
  display: flex;
  height: 100vh;

  .formcontent {
    width: 50%;
    height: 100%;
    background-color: #fff9f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 2vh;
      left: 14%;
    }

    h5 {
      font-size: 12px;
      position: absolute;
      bottom: 10px;
    }
    h1 {
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      color: #5e5e5e;
      font-size: 34px;
    }
    p {
      line-height: 20px;
      letter-spacing: 1%;
      font-size: 12px;
      font-weight: 400;
    }
    .forms {
      width: 45%;
      min-width: 250px;
      display: flex;
      flex-direction: column;
      margin-top: 4rem;

      .error {
        background-color: #e74c3c;
        font-size: 16px;
        color: white;
        border-radius: 3px;
        padding: 10px 10px;
        margin: 5px 3px;
      }

      .success {
        background-color: #2ecc71;
        font-size: 16px;
        color: white;
        border-radius: 3px;
        padding: 10px 10px;
        margin: 5px 3px;
      }

      h1,
      p {
        margin-bottom: 1rem;
      }
      label {
        margin-top: 1rem;
        width: 100%;

        h1 {
          font-size: 14px !important;
          margin-bottom: 1rem;
        }
        input {
          width: 100%;
          height: 35px;
          border: 1px solid rgb(199, 198, 198);
          border-radius: 3px;
          padding-left: 10px;
          box-sizing: border-box;
        }
      }
      button {
        width: 100%;
        background-color: #daa335;
        color: white;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        padding: 12px;
        border-radius: 3px;
        margin-top: 37px;
        cursor: pointer;
      }
    }
  }
  .banner {
    width: 50%;
    // background: url(../../assets/img/loginbanner.png) no-repeat;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    display: flex;
    input {
      margin-right: 3px;
      width: 10% !important;
      height: 15px !important;
    }
    h1 {
      font-size: 12px !important;
      font-weight: 400;
    }
  }
  a {
    width: 200px;
    font-size: 14px;
    color: #daa335;
  }
}

// Mobil cihazlar için düzenlemeler
@media (max-width: 768px) {
  .innerContainer .formcontent {
    width: 100%;
  }
  .innerContainer .banner {
    width: 0%;
  }
  .innerContainer img {
    display: none;
  }
}
