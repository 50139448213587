@use "../../assets/style/index" as *;

.raporContainer {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  .content {
    width: 100%;
    display: flex;
    height: 85vh;

    .info {
      background: $info-background;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #daa335;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #daa335;
    }
  }
}

.rapors {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid $border-stroke-color;
  background: white;
  padding: 30px 35px;
  box-sizing: border-box;
  padding-right: 170px;
}
