@use "../../assets/style/index" as *;

.editContactContent {
  background: $info-background;
  width: 99vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  padding-bottom: 40px;

  .editContact {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    width: 87%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-radius: 3px;

    .leftContactEdit {
      margin-right: 1rem;
      @media (min-width: 1000px) {
        margin-right: 3rem;
      }
      @media (min-width: 1200px) {
        margin-right: 5rem;
      }
    }
    @media (max-width: 1000px) {
      width: 98%;
    }
    @media (max-width: 1200px) {
      width: 95%;
    }
    @media (max-width: 950px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #daa335;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #daa335;
}
.cardPopupWrapper {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}
.cardPopup {
  width: 500px;
  height: 250px;
  background: white;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  h1 {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 25px;
    padding-top: 1rem;
  }
  label {
    margin-top: 3rem;
    button {
      margin-left: 1rem;
    }
  }
}
.shadowBox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 50;
}

.closePopup {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.putPopupLabel {
  margin-top: 1rem;
  input {
    border: 1px solid rgb(177, 177, 177);
    padding: 0.4rem 1rem;
    border-radius: 5px;
  }
}
.closeButtonPopup {
  padding: 0.5rem;
  width: 100px;
  background-color: rgb(184, 100, 100);
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.successButtonPopup {
  border-radius: 4px;
  color: white;
  cursor: pointer;

  padding: 0.5rem;
  background-color: rgb(73, 182, 73);
  width: 100px;
}
