@use "../../assets/style/index" as *;

.inventoryDetail {
  width: 100%;
  //   overflow-y: hidden;
  overflow-x: hidden;
  overflow-y: hidden;

  .content {
    width: 100%;
    display: flex;
    height: 85vh;
    overflow-y: hidden;

    .info {
      background: $info-background;
      width: 90%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow: scroll;
      overflow-x: hidden;
      height: 100%;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #daa335;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #daa335;
    }
  }
}
.inventoryCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  padding: 30px 35px;
  box-sizing: border-box;
  .ownerCard {
    display: flex;
    height: 100px;

    .ownerInfo {
      display: flex;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-left: 10px;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        div {
          color: $button-icon-color;
          font-family: $poppins-font;
          display: flex;
          flex-direction: row;
          margin-left: 0;
          margin-top: 5px;
          font-size: 14px;

          h4 {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.card {
  display: flex;
  color: $primary-color;
  align-items: center;
  height: 40px;

  h2 {
    font-size: 14px;
    width: 200px;
    font-weight: 400;
    text-align: end;
    margin-right: 1rem;
  }

  h1 {
    width: 240px;
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    margin-left: 15px;
    word-wrap: break-word;
  }
  a {
    margin-left: 15px;

    width: 240px;
    cursor: pointer;
    color: #3b91ca;
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    line-height: 23px;
  }
}
.inventoryFiles {
  font-size: 13px !important;
}

.box {
  background-color: #daa335;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin-bottom: 10px;
  padding: 20px;

  .textBox {
    background-color: #f0f0f0;
    padding: 10px;
    margin: 10px;
  }

  h1 {
    width: 240px;
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    margin-left: 15px;
    word-wrap: break-word;
    color: white;
  }

  .button {
    border: 1px solid #ccc;
    padding: 5px 10px;
    background-color: #f9f9f9;
    cursor: pointer;
  }

  .button:hover {
    background-color: #e0e0e0;
  }
}
