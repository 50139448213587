@use "../../assets/style/index" as *;

.textInput {
  height: 40px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  margin-bottom: 20px;

  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
  }

  button {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
    background-color: transparent;
    color: rgba(16, 16, 16, 0.484);
    cursor: pointer;
    &:hover {
      background-color: $border-stroke-color;
    }
  }
}
.numberAndDrop {
  height: 40px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  margin-bottom: 20px;
  label {
    width: 100px;
  }
  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    padding-left: 10px;
  }
  select {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    color: #6e6d6d;
    margin-left: 1rem;
    width: 140px;
    option {
      color: #6e6d6d;
    }
  }
}
.withButtonInput {
  height: 40px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  margin-bottom: 20px;

  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 250px;
    padding-left: 10px;
  }
  button {
    cursor: pointer;
    margin-left: 0.5rem;
    padding: 0.5rem 0.5rem;
    font-size: 14px;
    border-radius: 5px;
    color: #6e6d6d;
  }
}
.textarea {
  height: 130px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  margin-bottom: 20px;
  box-sizing: border-box;
  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  textarea {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
    padding-top: 10px;
  }
}

.disabledInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  h2 {
    margin-right: 10px;
    color: $primary-color;
  }

  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
    background: $input-border;
  }
}

.dropInput {
  height: 40px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  margin-bottom: 18px;
  box-sizing: border-box;
  select {
    padding: 10px;
    box-sizing: border-box;
  }
  h2 {
    margin-right: 32px;
    width: 150px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
    background: url("../../assets/img/downIcon.svg"), white;
    background-repeat: no-repeat;
    background-position: 95%;
  }
}

.userInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative;

  h2 {
    // width: 120px;
    // text-align: end;
    // margin-right: 10px;
    // font-size: 14px;
    // color: #6e6d6d;
    // font-weight: 600;
    width: 110px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  button {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid $stroke-button;
    border-radius: 5px;

    path {
      stroke: $button-icon-color;
    }
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
  }
}

.documentInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative;

  h2 {
    margin-right: 10px;
    color: $primary-color;
  }

  button {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid $stroke-button;
    border-radius: 5px;

    path {
      stroke: $button-icon-color;
    }
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
  }
}

.accountInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative;

  h2 {
    margin-right: 10px;
    color: $primary-color;
  }

  button {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid $stroke-button;
    border-radius: 5px;

    path {
      stroke: $button-icon-color;
    }
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
  }
}

.contactInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative;

  h2 {
    margin-right: 10px;
    color: $primary-color;
  }

  button {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid $stroke-button;
    border-radius: 5px;

    path {
      stroke: $button-icon-color;
    }
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
  }
}

.dateInput {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  button {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid $stroke-button;
    border-radius: 5px;

    path {
      stroke: $button-icon-color;
    }
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 290px;
    padding-left: 10px;
    padding-right: 10px;
    color: $primary-color;
  }
}
.dateAndInputContent {
  display: flex;
  width: 310px;
  justify-content: space-between;
  input {
    height: 40px !important;
    height: 100%;
    border-radius: 5px;
    width: 140px;
    padding-left: 10px;
    color: $primary-color;
  }
}

.checkboxInput {
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  button {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid $stroke-button;
    border-radius: 5px;

    path {
      stroke: $button-icon-color;
    }
  }
  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 290px;
    padding-left: 10px;
    padding-right: 10px;
    color: $primary-color;
  }
}

.leadForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 78%;
  padding-top: 1rem;
  padding-left: 1rem;
  background-color: white;
  border: 1px solid rgb(224, 219, 219);
  border-radius: 2px;
  // height: 600px;
  max-height: 500px;
  padding-bottom: 1.4rem;
  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}

.contactForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}

.offerForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;

  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}
.documenSelect {
  color: $primary-color;
}
.newSaleOfferForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}
.selectOption {
  width: 310px !important;
  height: 40px !important;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 5px;
  color: grey;
  option {
    color: grey;
    width: 310px !important;
  }
}
.secondhandOfferForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}
.dropInput2 {
  height: 40px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  margin-bottom: 18px;
  box-sizing: border-box;
  select {
    padding: 3px 10px;
    box-sizing: border-box;
  }
  h2 {
    margin-right: 32px;
    width: 120px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  input {
    border: 1px solid $border-stroke-color;
    height: 100%;
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
    background: url("../../assets/img/downIcon.svg"), white;
    background-repeat: no-repeat;
    background-position: 95%;
  }
}
.rentOfferForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}

.creditForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 70%;

  h1 {
    font-size: 18px;
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }
}
.documentDrop {
  position: relative;
  display: flex;
  align-items: center;
  h2 {
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    margin-right: 32px;
    width: 150px;
    text-align: end;
  }
  label {
    border: 1px solid $border-stroke-color;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 38px;
    width: 308px;
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: normal;
    }
  }
  input {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
}

.dateContainer {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  box-sizing: border-box;

  h2 {
    width: 120px;
    margin-right: 32px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  .pickerButton {
    border: 1px solid $border-stroke-color;
    height: 40px !important;
    border-radius: 5px;
    width: 312px !important;
    cursor: pointer;
    background-color: transparent;
    padding-left: 10px;
  }
  .datePicker {
    height: 40px !important;
    width: 312px !important;
  }
  .reminder {
    width: 290px !important;
    background-color: rgba(239, 239, 239, 0.68);
    padding: 10px 10px;
    border-radius: 5px;
    margin-top: 20px !important;
    font-size: 13px;
    line-height: 1.3;
    text-align: left;
  }
}

.selected {
  background-color: #f0f8ff; /* Seçili öğelerin arka plan rengi (AliceBlue) */
  font-weight: bold; /* Seçili öğelerin yazı tipi kalınlığı */
  color: #333; /* Seçili öğelerin metin rengi */
}
