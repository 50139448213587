@use "../../assets/style/index" as *;

.editBidMain {
  width: 100%;
  .content {
    background: $info-background;
    padding-top: 33px;
  }
}

.editBidContent {
  background: $info-background;
  width: 99vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 40px;
  .bidForm {
    display: flex;
    margin-left: 10%;
    width: 87%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-radius: 3px;

    .leftBidForm {
      margin-right: 5rem;
    }
  }
}
