@use "../../assets/style/index" as *;

.contactDetail {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  .content {
    width: 100%;
    display: flex;
    height: 85vh;

    .info {
      background: $info-background;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow: scroll;
      overflow-x: hidden;
      // overflow: scroll;
      // scroll-behavior: smooth;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #daa335;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #daa335;
    }
  }
}
