.tableContainer {
  flex: 1;
  height: 100%;
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  .titleContainer {
    //min-width: 100%;
    // position: absolute;
    position: sticky;
    top: 0px;
    z-index: 20;
    -webkit-box-shadow: 4px 8px 21px -4px rgba(0, 0, 0, 0.08);
    box-shadow: 4px 8px 21px -4px rgba(0, 0, 0, 0.08);

    border-bottom-style: solid;
    border-color: #e5e5e5;
    background-color: white;
    border-width: 1px;
    height: 40px;
    flex-direction: row;
    display: flex;
    align-items: center;
    th {
      margin-left: 10px;
      font-size: 14px;
      text-align: left;
      font-weight: 500;

      & span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2px;
      }
    }
  }

  .tableBody {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll !important;

    .tableListItem {
      height: 70px !important;
      width: 100%;
      min-height: 30px;
      align-items: center;
      display: flex;
      flex-direction: row;
      border: 1px solid #e5e5e5;
      background: white;
      word-wrap: break-word;
      padding-top: 5px;
      padding-bottom: 5px;
      text-decoration: none;
      color: grey;

      &:hover {
        background: #f5f5f5a4;
      }
      td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        font-size: 13px;
        font-weight: 400;
        text-align: left;
        margin-left: 10px;
        flex: 3;
        position: relative;

        .active {
          // position: absolute;
          // left: -15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          width: 70px;
          height: 40px;
          color:"#000000";
          background-color: rgba(79, 219, 79, 0.169);
        }
        .passive {
          // position: absolute;
          // left: -15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          width: 70px;
          height: 40px;
          color:"#000000";
          line-height: 22px;
          background-color: rgba(255, 0, 0, 0.169);
        }

        & input {
          justify-self: flex-start !important;
          position: absolute;
          top: auto;
          left: auto;
        }
      }
    }
  }

  .pagination {
    position: sticky;
    bottom: 0px;
    z-index: 20;
    border-top: 1px solid rgba(173, 173, 173, 0.298);
    background-color: white;
    padding: 7px 15px;
    font-size: 14px;
    -webkit-box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.09);

    .button {
      background-color: #daa335;
      color: white;
      padding: 8px 15px;
      border-radius: 5px;
      margin: 0 2px;
      cursor: pointer;

      &:hover {
        background-color: #e4be5e;
      }
    }
    .input {
      border: 2px solid #e4c36f74;
      border-radius: 3px;
      padding: 3px 10px;
      width: 50px;
      margin-right: 8px;
    }
  }
}
