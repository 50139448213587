@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
$primary-color: #333e48;
$secondary-color: #daa335;
$black-color: #292929;
$dark-grey-color: #9d9d9d;
$soft-grey-color: #e8e8e8;
$stroke-primary-color: #cd8a05;
$stroke-button: #efefef;
$button-color: #5e5e5e;
$button-icon-color: #acacac;
$white-color: white;
$border-stroke-color: #e5e5e5;
$head-border-stroke-color: #e9e9e9;
$input-border: #cfcfcf;

$info-background: #fff9f3;

$raleway-font: "Raleway", sans-serif;
$work-sans-font: "Inter", sans-serif;
$poppins-font: "Inter", sans-serif;

body {
  font-family: $work-sans-font;
}
body > iframe {
  pointer-events: none;
}

iframe {
  pointer-events: none;
}
