@use "../../assets/style/index" as *;

.createBidContent {
  background: $info-background;
  width: 99vw;
  height: 100vh;
  padding-top: 33px;
  .bidForm {
    display: flex;
    margin-left: 10%;
    width: 87%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-radius: 3px;

    .leftBidForm {
      margin-right: 5rem;
    }
  }
}
