@use "../../assets/style/index" as *;

.createLeadContent {
  background: $info-background;
  width: 100%;
  height: 100vh;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .leadForm {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 90%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding: 30px;
    border-radius: 3px;

    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .leftLeadForm {
      width: 50%;
    }

    .rightLeadForm {
      width: 50%;

      .checkboxArea {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        gap: 10px;
        margin: 25px 0;
        margin-left: 50px;
      }
    }
  }
}
.cardPopupWrapper {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}
.cardPopup {
  width: 500px;
  height: 250px;
  background: white;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  h1 {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 25px;
    padding-top: 1rem;
  }
  label {
    margin-top: 3rem;
    button {
      margin-left: 1rem;
    }
  }
}
.shadowBox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 50;
}

.closePopup {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.putPopupLabel {
  margin-top: 1rem;
  input {
    border: 1px solid rgb(177, 177, 177);
    padding: 0.4rem 1rem;
    border-radius: 5px;
  }
}
.closeButtonPopup {
  padding: 0.5rem;
  width: 100px;
  background-color: rgb(184, 100, 100);
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.successButtonPopup {
  border-radius: 4px;
  color: white;
  cursor: pointer;

  padding: 0.5rem;
  background-color: rgb(73, 182, 73);
  width: 100px;
}
