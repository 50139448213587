@use "../../assets/style/index" as *;

.editInventoryContent {
  background: $info-background;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem !important;

  .editInventory {
    display: flex;
    margin-left: 10%;
    width: 87%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
    border-radius: 3px;

    .leftInventoryEdit {
      margin-right: 5rem;
    }
  }
}

.box {
  background-color: #daa335;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin: 20px;
  margin-left: 10%;
  padding: 20px;
  float: left;

  .textBox {
    background-color: #f0f0f0;
    padding: 10px;
    margin: 10px;
  }

  h1 {
    width: 240px;
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    margin-left: 15px;
    word-wrap: break-word;
    color: white;
  }

  .button {
    border: 1px solid #ccc;
    padding: 5px 10px;
    background-color: #f9f9f9;
    cursor: pointer;
  }

  .button:hover {
    background-color: #e0e0e0;
  }
}

.editDownloadLink {
  display: flex;
  margin-left: 30%;
  width: 87%;
  background-color: white;
  padding-top: 10px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
  border-radius: 3px;
  text-align: center;
}
