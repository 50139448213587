@use "../../../assets/style/index.scss" as *;

.checkboxContact {
  position: relative;

  z-index: 50;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  height: 40px;
  box-sizing: border-box;

  .checkboxTitle {
    margin-right: 32px;
    width: 120px;
    font-size: 14px;
    color: #6e6d6d;
    font-weight: 600;
    text-align: end;
  }

  button {
    width: 312px;
    height: 40px;
    border: 1px solid $stroke-button;
    border-radius: 5px;
    font-size: 14px;
    background: transparent;
    color: $button-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    &:hover {
      background-color: rgb(240, 240, 240);
    }
  }
  .select {
    width: 280px;
    padding: 10px 10px;
    position: absolute;
    top: 44px;
    left: 152px;
    background-color: rgb(248, 248, 248);
    border: 1px solid $stroke-button;
    margin-top: 0px;
    border-radius: 3px;
    max-height: 240px;
    overflow: hidden;
    cursor: pointer;
    -webkit-box-shadow: 0px 10px 40px -3px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 10px 40px -3px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    .items {
      width: 100%;
      max-height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px 0;
    }
    .selectInput {
      border: 2px solid $stroke-button;
      background-color: white;
      color: black;
      border-radius: 3px;
      width: 100%;
      padding: 8px 15px;
    }

    label {
      display: flex;
      align-items: center;
      padding: 5px 0;
      cursor: pointer;
      h1 {
        color: $button-color;
        font-size: 14px;
      }
      input {
        width: 25px;
        color: $button-color;
      }
    }
  }
}
