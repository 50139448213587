@use "../../assets/style/index" as *;

.sideNav {
  width: 12%;
  height: 90vh;
  position: relative;
  border: 1px solid $border-stroke-color;
  top: -2px;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $primary-color;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .navWrapper {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $primary-color;
      position: relative;

      span {
        text-align: center;
        position: absolute;
        left: 6rem;
        padding: 0px 5px;
        font-size: 14px;
        padding-bottom: 0.2rem;
        width: 8px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px 7px 7px 7px;
        background: #daa335;
        border: 1px solid #cd8a05;
        color: white;
      }
    }
  }
}
.active {
  color: #daa335 !important;
}
