@use "../../assets/style/index" as *;

.leadCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  padding: 30px 35px;
  box-sizing: border-box;
  max-height: 460px;
  .column {
    width: 50%;
  }
  .ownerCard {
    display: flex;
    height: 100px;

    .ownerInfo {
      display: flex;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-left: 10px;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        div {
          color: $button-icon-color;
          font-family: $poppins-font;
          display: flex;
          flex-direction: row;
          margin-left: 0;
          margin-top: 5px;
          font-size: 14px;

          h4 {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .card {
    display: flex;
    color: $primary-color;
    align-items: center;
    height: 40px;
    position: relative;

    h2 {
      font-size: 14px;
      width: 100px;
      font-weight: 400;
      text-align: end;
      margin-right: 1rem;
    }

    h1 {
      position: relative;
      width: 240px;
      font-size: 18px;
      font-weight: 500;
      font-family: $poppins-font;
      margin-left: 15px;
      word-wrap: break-word;
    }
  }
}

.leadDetail {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  .content {
    width: 100%;
    display: flex;
    height: 85vh;

    .info {
      background: $info-background;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow: scroll;
      overflow-x: hidden;
      // overflow: scroll;
      // scroll-behavior: smooth;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #daa335;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #daa335;
    }
  }
}
