@use "../../assets/style/index" as style;

.projectPayment {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid style.$border-stroke-color;
  background: white;
  // padding: 25px 0px;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 31px;
  .projects {
    border-bottom: 1px solid style.$border-stroke-color;
    width: 100%;
    box-sizing: border-box;
    padding: 17px 25px;
  }
  .addProject {
    width: 700px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid style.$border-stroke-color;
    margin: 25px 25px;

    label {
      margin: 0.8rem 1rem;
      width: 600px;
      input,
      textarea {
        width: 600px;
      }
      textarea {
        height: 50px;
      }
    }
    .action {
      width: 100%;
      border-top: 1px solid style.$border-stroke-color;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      select {
        width: 300px;
        border: 1px solid style.$border-stroke-color;
        border-radius: 5px;
        color: style.$button-icon-color;
        font-family: style.$poppins-font;
      }
      h2 {
        width: 155px;
        margin: 0;
        box-sizing: border-box;
        padding: 5px;
        padding-top: 9px;
        color: rgb(49, 49, 49);
        margin-right: 1rem;
        font-family: style.$poppins-font;
      }
      .close {
        background-color: white;
        border: 1px solid style.$border-stroke-color;
        padding: 7px 20px;
        margin-right: 1rem;
        border-radius: 5px;
        color: style.$button-icon-color;
        cursor: pointer;
      }
      .save {
        border: 1px solid style.$border-stroke-color;
        padding: 7px 20px;
        border-radius: 5px;
        color: white;
        background-color: style.$secondary-color;
        cursor: pointer;
      }
    }
  }
  .fakeNotes {
    width: 300px !important;

    label {
      margin: 0 25px;
      input {
        margin-top: 1rem;
        border: 2px solid style.$border-stroke-color;
        padding: 15px 10px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 1rem;
      }
    }
  }
  .projectsItem {
    position: relative;
    width: 700px;
    padding: 17px 25px;
    &:hover {
      .actionDeleteAndEdit {
        visibility: visible;
      }
    }
    .title {
      font-size: 15px;
      font-weight: 700;
      font-family: style.$poppins-font;
      margin-bottom: 0.4rem;
      color: rgb(36, 36, 36);
    }
    .project {
      font-family: style.$poppins-font;
      color: rgb(61, 61, 61);
      word-wrap: break-word;
      margin-top: 1rem;
      span {
        position: absolute;
        cursor: pointer;
        font-size: 14px;
        margin-left: 0.2rem;
        border-bottom: 1px solid rgb(101, 101, 221);
        color: rgb(101, 101, 221);
      }
    }
    .footer {
      display: flex;
      margin-top: 1rem;
      align-items: center;
      justify-content: space-between;
      span {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        h2 {
          font-size: 14px;
          font-family: style.$poppins-font;
          color: grey;
        }
        h4 {
          color: rgb(125, 150, 231);
          font-family: style.$poppins-font;
          font-weight: 400;
          font-size: 14px;
        }
        h5 {
          color: rgb(70, 111, 247);
          font-family: style.$poppins-font;
          font-weight: 400;
        }
      }
      select {
        border: 1px solid style.$border-stroke-color;
        color: grey;
        padding: 4px 4px;
        margin-right: 1rem;
        border-radius: 5px;
        width: 50%;
      }
    }
    .actionDeleteAndEdit {
      visibility: hidden;
      display: flex;
      align-items: center;
      position: absolute;
      top: 17px;
      right: 20px;
      div {
        cursor: pointer;
        margin-right: 1rem;
      }
    }
  }
}
