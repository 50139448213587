.contactList {
  width: 100%;
  overflow-x: hidden !important;
  .content {
    width: 100%;
    display: flex;
    height: 85vh;
    ::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #daa335;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #daa335;
    }
  }
  .contactListItems {
    margin-top: 40px;
  }
  .listContainer {
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
  }

  .listTitleContainer {
    position: sticky;
    top: 0px;
    z-index: 20;
    -webkit-box-shadow: 4px 8px 21px -4px rgba(0, 0, 0, 0.08);
    box-shadow: 4px 8px 21px -4px rgba(0, 0, 0, 0.08);

    border-bottom-style: solid;
    border-color: #e5e5e5;
    border-width: 1px;
    height: 40px;
    flex-direction: row;
    display: flex;
    align-items: center;
    background: white;

    h2 {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .icons {
    height: 100%;
    display: flex;
    gap: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon {
      padding: 6px;
      border-radius: 5px;
      cursor: pointer;
      background-color: transparent;
      outline: none;
      text-decoration: none;

      & svg {
        color: rgb(39, 39, 39);
        stroke-width: 1.7;
      }

      &:hover,
      &:hover svg {
        background-color: #daa335;
        color: white !important;
      }
    }
  }
}
