@use "../../assets/style/index" as *;

.contactCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  padding: 30px 0px;
  box-sizing: border-box;

  .column {
    width: 50%;
  }

  .ownerCard {
    display: flex;
    height: 100px;

    .ownerInfo {
      display: flex;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-left: 10px;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        div {
          color: $button-icon-color;
          font-family: $poppins-font;
          display: flex;
          flex-direction: row;
          margin-left: 0;
          margin-top: 5px;
          font-size: 14px;

          h4 {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .infoWrapper {
    display: flex;

    .infoRight {
      margin-left: 30px;
    }
  }

  // .card {
  //   display: flex;
  //   color: $primary-color;
  //   height: 40px;

  //   h2 {
  //     font-size: 14px;
  //     font-weight: 400;
  //     width: 130px;
  //     text-align: end;
  //     // margin-right: 1rem;
  //   }

  //
  // }
}
.contactBid {
  margin-top: 31px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  box-sizing: border-box;
  .contactNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
    border-bottom: 1px solid $border-stroke-color;
    .rigtNav {
      display: flex;
      align-items: center;
      .delete {
        border-radius: 5px;
        margin-left: 1rem;
        padding: 6px 20px;
        font-size: 15px;
        cursor: pointer;
        border: 1px solid $border-stroke-color;
      }
    }
  }
  .contactBidİtemHead {
    border: 1px solid $border-stroke-color;
    border-radius: 3px;
    padding: 20px 0px;
    margin: 7px 7px;
    display: flex;
    flex: 1;
    div {
      flex: 3;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .contactBidİtem {
    border: 1px solid $border-stroke-color;
    border-radius: 3px;
    padding: 25px 0px;
    margin: 7px 7px;
    display: flex;
    flex: 1;
    .label {
      height: 15px;
      width: 15px;
      background-color: green;
      border-radius: 50%;
      display: inline-block;
    }
    div {
      font-size: 14px;
      flex: 3;
      text-align: center;
      color: grey;
    }
  }
}

.cards {
  display: flex;
  color: $primary-color;
  height: 40px;
  margin-left: 2.2rem;
  align-items: center;
  width: 100%;
  h2 {
    font-size: 14px;
    font-weight: 400;
    text-align: end;
    width: 100px;
    margin-right: 2rem;
  }
  h1 {
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    width: 70%;
    line-height: 23px;
  }
}
.cardID {
  display: flex;
  color: $primary-color;
  height: 40px;
  margin-right: 1rem;

  h2 {
    font-size: 14px;
    width: 100px;
    font-weight: 400;
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    margin-left: 15px;
  }
}

.cardElement {
  width: 100%;
  background: white;
  margin-top: 20px;
  border: 1px solid $border-stroke-color;

  .header {
    height: 40px;
    border-bottom: 1px solid $border-stroke-color;
    color: $primary-color;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  .children {
    padding: 30px;
  }
}

.contactListItem {
  width: 100%;
  height: auto;
  min-height: 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border: 1px solid $border-stroke-color;
  background: white;
  word-wrap: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  div {
    font-size: 10px;
    font-weight: 400;
    font-family: $poppins-font;
  }
}

.leadCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  padding: 30px 35px;
  box-sizing: border-box;
  max-height: 300px;

  .ownerCard {
    display: flex;
    height: 100px;

    .ownerInfo {
      display: flex;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-left: 10px;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        div {
          color: $button-icon-color;
          font-family: $poppins-font;
          display: flex;
          flex-direction: row;
          margin-left: 0;
          margin-top: 5px;
          font-size: 14px;

          h4 {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .card {
    display: flex;
    color: $primary-color;
    height: 40px;

    h2 {
      font-size: 14px;
      width: 100px;
      font-weight: 400;
      text-align: end;
      margin-right: 1rem;
    }

    h1 {
      width: 240px;
      font-size: 18px;
      font-weight: 500;
      font-family: $poppins-font;
      margin-left: 15px;
      word-wrap: break-word;
    }
  }
}

.cardElement {
  width: 100%;
  background: white;
  margin-top: 20px;
  border: 1px solid $border-stroke-color;

  .header {
    height: 40px;
    border-bottom: 1px solid $border-stroke-color;
    color: $primary-color;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  .children {
    padding: 30px;
  }
}

.leadListItem {
  height: 70px !important;
  width: 100%;
  height: auto;
  min-height: 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border: 1px solid $border-stroke-color;
  background: white;
  word-wrap: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  color: grey;

  &:hover {
    background: #f5f5f5a4;
  }

  input {
    flex: 1;
  }
  div {
    font-size: 11px;
    font-weight: 400;
    font-family: $poppins-font;
    flex: 3;
  }

  button {
    background-color: white;
    cursor: pointer;
  }
}
.contactUploadFile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 31px;
  .upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-stroke-color;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 25px;
    position: relative;
    input {
      opacity: 0;
      z-index: 1;
      width: 12.5%;
      top: 11.5px;
      height: 60%;
      position: absolute;
      right: 100px;
    }
    .buttons {
      display: flex;
      align-items: center;
      select {
        border: 1px solid $border-stroke-color;
        padding: 3px;
        border-radius: 5px;
        font-family: $poppins-font;

        color: #acacac;
        margin-right: 16px;
        width: 300px;
      }
      h2 {
        margin-right: 1rem;
        font-family: $poppins-font;
      }
      button {
        background-color: #cd8a05;
      }
      .delete {
        border-radius: 5px;
        margin-left: 1rem;
        padding: 7px 20px;
        font-size: 15px;
        color: #acacac;

        cursor: pointer;
        border: 1px solid $border-stroke-color;
      }
    }
  }
  .navUpload {
    background-color: #f2f3f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-stroke-color;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 25px;
    position: relative;
    box-sizing: border-box;
    div {
      font-size: 14px;
      font-weight: bold;
      color: rgb(53, 53, 53);
    }
  }
  .uploadItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-stroke-color;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 25px;
    position: relative;
    box-sizing: border-box;
    div {
      font-family: $poppins-font;
      font-size: 14px;
      .fileName {
        margin-left: 1rem;
        color: #5dade2;
        cursor: pointer;
      }
      .fileName:hover {
        border-bottom: 1px solid #5dade2;
      }
    }
  }
}
// .leadListItem:nth-child(2) {
//   margin-top: 40px;
// }
.contactNote {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-stroke-color;
  background: white;
  // padding: 25px 0px;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 31px;
  .notes {
    border-bottom: 1px solid $border-stroke-color;
    width: 100%;
    box-sizing: border-box;
    padding: 17px 25px;
  }
  .addNote {
    width: 700px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid $border-stroke-color;
    margin: 25px 25px;

    label {
      margin: 0.8rem 1rem;
      width: 600px;
      input,
      textarea {
        width: 600px;
      }
      textarea {
        height: 50px;
      }
    }
    .action {
      width: 100%;
      border-top: 1px solid $border-stroke-color;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      select {
        width: 300px;
        border: 1px solid $border-stroke-color;
        border-radius: 5px;
        color: $button-icon-color;
        font-family: $poppins-font;
      }
      h2 {
        width: 155px;
        margin: 0;
        box-sizing: border-box;
        padding: 5px;
        padding-top: 9px;
        color: rgb(49, 49, 49);
        margin-right: 1rem;
        font-family: $poppins-font;
      }
      .close {
        background-color: white;
        border: 1px solid $border-stroke-color;
        padding: 7px 20px;
        margin-right: 1rem;
        border-radius: 5px;
        color: $button-icon-color;
        cursor: pointer;
      }
      .save {
        border: 1px solid $border-stroke-color;
        padding: 7px 20px;
        border-radius: 5px;
        color: white;
        background-color: #daa335;
        cursor: pointer;
      }
    }
  }
  .fakeNotes {
    width: 300px !important;

    label {
      margin: 0 25px;
      input {
        margin-top: 1rem;
        border: 2px solid $border-stroke-color;
        padding: 15px 10px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 1rem;
      }
    }
  }
  .notesItem {
    position: relative;
    width: 700px;
    padding: 17px 25px;
    &:hover {
      .actionDeleteAndEdit {
        visibility: visible;
      }
    }
    .title {
      font-size: 15px;
      font-weight: 700;
      font-family: $poppins-font;
      margin-bottom: 0.4rem;
      color: rgb(36, 36, 36);
    }
    .note {
      font-family: $poppins-font;
      color: rgb(61, 61, 61);
      word-wrap: break-word;
      margin-top: 1rem;
      span {
        position: absolute;
        cursor: pointer;
        font-size: 14px;
        margin-left: 0.2rem;
        border-bottom: 1px solid rgb(101, 101, 221);
        color: rgb(101, 101, 221);
      }
    }
    .footer {
      display: flex;
      margin-top: 1rem;
      align-items: center;
      justify-content: space-between;
      span {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        h2 {
          font-size: 14px;
          font-family: $poppins-font;
          color: grey;
        }
        h4 {
          color: rgb(125, 150, 231);
          font-family: $poppins-font;
          font-weight: 400;
          font-size: 14px;
        }
        h5 {
          color: rgb(70, 111, 247);
          font-family: $poppins-font;
          font-weight: 400;
        }
      }
      select {
        border: 1px solid $border-stroke-color;
        color: grey;
        padding: 4px 4px;
        margin-right: 1rem;
        border-radius: 5px;
        width: 50%;
      }
    }
    .actionDeleteAndEdit {
      visibility: hidden;
      display: flex;
      align-items: center;
      position: absolute;
      top: 17px;
      right: 20px;
      div {
        cursor: pointer;
        margin-right: 1rem;
      }
    }
  }
}

.p5 {
  margin: 5px;
}

.logList {
  list-style: none;
  max-height: 300px; /* Örnek olarak, istediğiniz yüksekliği belirleyin */
  overflow-y: auto; /* Yalnızca yükseklik sınırlarını aştığında kaydırma çubuğunu göster */
  border: 1px solid #ccc; /* İsteğe bağlı: çerçeve eklemek için */
  padding: 10px; /* İsteğe bağlı: içeriği çerçeveden ayırmak için */
}

.logItem {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.createdBy {
  font-weight: bold;
  margin-right: 10px;
}

.createdAt {
  color: #555;
}

.ip {
  font-weight: bold;
  margin-left: 10px;
}

.logDetails {
  margin-top: 5px;
}

.logDetailItem {
  margin-bottom: 5px;
}

.detailKey {
  font-weight: bold;
  margin-right: 5px;
}

.detailValue {
  color: #555;
}

.modalContent {
  padding: 20px;

  .detailRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    align-items: center;

    span:first-child {
      font-weight: 500;
      flex: 1;
    }

    span:last-child,
    input,
    select {
      flex: 2;
      margin-left: 20px;
    }

    input,
    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      &:focus {
        outline: none;
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }
  }

  .mainInfo {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;

    h3 {
      margin-bottom: 20px;
    }
  }

  .installmentsSection {
    h4 {
      margin-bottom: 15px;
    }

    .installmentsHeader {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 1fr;
      padding: 10px;
      background-color: #f5f5f5;
      font-weight: 500;
      border-radius: 4px;
      gap: 10px;
    }

    .installmentRow {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 1fr;
      padding: 10px;
      border-bottom: 1px solid #f0f0f0;
      align-items: center;
      gap: 10px;

      &:hover {
        background-color: #fafafa;
      }

      input[type="number"],
      input[type="date"],
      select {
        width: 100%;
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: #40a9ff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
      }

      select {
        background-color: white;
        height: 36px;
      }

      input[type="checkbox"] {
        width: auto;
        margin: 0 auto;
        transform: scale(1.2);
      }
    }

    .totalRow {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 1fr;
      padding: 15px 10px;
      border-top: 2px solid #f0f0f0;
      margin-top: 10px;
      font-weight: 600;
      background-color: #fafafa;
      align-items: center;
      gap: 10px;

      span:first-child {
        text-align: right;
        padding-right: 20px;
      }

      span:nth-child(2) {
        color: #1890ff;
      }
    }
  }
}

.editButton,
.saveButton {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
}

.editButton {
  background-color: #fff;
  border: 1px solid #d9d9d9;

  &:hover {
    border-color: #40a9ff;
    color: #40a9ff;
  }
}

.saveButton {
  background-color: #1890ff;
  border: 1px solid #1890ff;
  color: white;

  &:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
}

.contactPaymentItem {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
}
