@use "../../assets/style/index" as *;

.bidDetail {
  width: 100%;
  .content {
    width: 100%;
    display: flex;
    height: 90vh;
    overflow: hidden;

    .info {
      background: $info-background;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow: scroll;

      .content2 {
        width: 100%;
        display: flex;
        border: 1px solid $border-stroke-color;
        background: white;
        box-sizing: border-box;
        padding: 20px 0px;
      }
    }
  }
}
.column {
  width: 50%;
}
.cards {
  align-items: center;
  display: flex;
  color: $primary-color;
  height: 40px;
  margin-left: 2.2rem;
  width: 100%;
  h2 {
    font-size: 14px;
    font-weight: 400;
    text-align: end;
    width: 180px;
    margin-right: 2rem;
  }
  h1 {
    font-size: 18px;
    font-weight: 500;
    font-family: $poppins-font;
    width: 70%;
    line-height: 23px;
  }
  a,
  div {
    cursor: pointer;
    color: #3b91ca;
    font-size: 16px;
    font-weight: 500;
    font-family: $poppins-font;
    width: 70%;
    line-height: 23px;
  }
}
