@use "../../assets/style/index" as *;

.createLeadContent {
  background: $info-background;
  width: 100%;
  height: 100vh;
  padding-top: 2rem;

  .leadForm {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-left: 10%;
    width: 85%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-radius: 3px;

    .leftLeadForm {
      .checkboxContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 40px;
        margin-bottom: 20px;
        margin-left: 90px;
      }
    }
    .rightLeadForm {
    }
  }
}
