@use "../../assets/style/index" as *;

.insideInput {
  border: 1px solid $border-stroke-color;
  width: 380px;
  height: 50px;
  border-radius: 5px;
  padding-left: 15px;
}

.textarea {
  height: 150px;
}

.drop {
  background: url("../../assets/img/downIcon.svg");
  background-repeat: no-repeat;
  background-position: 95%;
}

.emailInput {
  height: 350px;
}

.document {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: $button-icon-color;
  }
  button {
    padding: 0 10px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid $stroke-primary-color;
    border-radius: 5px;
    font-size: 14px;
    background: $secondary-color;
    color: white;

    svg {
      margin-left: 5px;
    }

    path {
      stroke: white;
    }
  }
}

.sms {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-items: flex-start;

  .leftSms,
  .rightSms {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      color: $button-color;
      margin-right: 10px;
    }
  }
}

.email {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-items: flex-start;

  .leftEmail,
  .rightEmail {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .centerEmail {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    .emailArea {
      width: 90%;

      textarea {
        border: 1px solid $border-stroke-color;
        width: 90%;
        height: 200px;
        border-radius: 5px;
        padding-left: 15px;
        padding-top: 15px;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      color: $button-color;
      margin-right: 10px;
    }
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  button {
    padding: 0 10px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid $stroke-primary-color;
    border-radius: 5px;
    font-size: 14px;
    background: $secondary-color;
    color: white;

    svg {
      margin-left: 5px;
    }
  }
}
