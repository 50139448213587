@use "../../assets/style/index" as *;

.createContact {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;

  .content {
    width: 100%;
    height: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    background: $info-background;
    padding-top: 60px;
  }
}
