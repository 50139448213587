@use "../../assets/style/index" as *;

.cardPopupWrapper {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}
.cardPopup {
  min-width: 500px !important;
  word-break: break-all;
  background: white;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 25px 5px 0px 5px;
  .inputContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    max-height: 380px;
    overflow-x: hidden;
    overflow-y: scroll;
    .inputPart {
      display: flex;
      flex-direction: row;
      gap: 0px 15px;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;
      .deleteButton {
        height: 40px;
        width: 40px;
        background-color: rgb(184, 100, 100);
        transition: 0.1s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: "bold";
        cursor: pointer;
        border-radius: 4px;
        margin-bottom: 20px;
        &:hover {
          background-color: rgb(195, 120, 100);
          color: white;
        }
      }
    }
    .addButtonContainer {
      width: 100%;
      min-width: 700px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 20px;

      .addNewButton {
        height: 50px;
        width: 95%;
        border-radius: 4px;
        background-color: transparent;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $border-stroke-color;
        color: "black";
        cursor: pointer;
        transition: 0.1s ease-in-out;
        &:hover {
          background-color: $border-stroke-color;
          color: "black";
        }
      }
    }
  }
}

.shadowBox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 50;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  margin: 5px 0 20px 0;
}

.closeModalPopup {
  padding: 10px 25px;
  background-color: rgb(14, 13, 13);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
.closeModalPopup:hover {
  background-color: rgba(24, 23, 23, 0.848);
  color: white;
}

.successModalPopup {
  padding: 10px 25px;
  background-color: rgb(73, 182, 73);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.successModalPopup:hover {
  background-color: rgb(90, 202, 90);
  color: white;
}
