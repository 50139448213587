@use "../../assets/style/index" as *;

.sideFilter {
  width: 15%;
  height: 100% !important;
  border: 1px solid $border-stroke-color;
  position: relative;
  top: -2px;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 18px;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #daa335;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #daa335;
  }
  .inputWrapper {
    height: 85%;
    overflow-x: hidden;
  }

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $primary-color;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .input {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    h3 {
      color: $primary-color;
      margin-left: 7px;
    }
  }

  .inputBox {
    width: 80%;
    border: 1px solid $stroke-button;
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
    height: 20px;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    position: relative;
    height: 10%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    button {
      padding: 0 10px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid $stroke-primary-color;
      border-radius: 5px;
      font-size: 14px;
      background: $secondary-color;
      color: white;

      svg {
        margin-left: 5px;
      }
    }

    button:last-child {
      background: transparent;
      border: 1px solid $stroke-button;
      color: $button-color;
      margin-top: 10px;
    }
  }
}

select::-ms-expand {
  display: none !important;
}
.dropDownLabel {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  .dropdownSelect {
    width: 100%;
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    padding-left: 1.2rem;
    color: $primary-color;
    line-height: 20px;
  }
  .downIcon {
    position: absolute;
  }
}
.dateInputQuery {
  display: flex;
  align-items: center;
  // margin-left: 1.2rem;
  box-sizing: border-box;

  input {
    width: 100%;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.1);
  }

  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }
}
