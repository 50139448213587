.cardPopupWrapper {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}
.cardPopup {
  max-width: 450px;
  min-width: 380px !important;
  word-break: break-all;
  background: white;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;

  .popupHeader {
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0 0 50px;
    color: #525252;
    text-align: left;
  }

  hr {
    width: 90%;
    border: 1px solid #eaeaea;
  }

  .textcontainer {
    word-break: normal;
    padding: 15px 30px !important;
    text-align: justify;
    width: 90%;
    line-height: 25px;
  }
}
.shadowBox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 50;
}

.closePopup {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.putPopupLabel {
  margin-top: 1rem;
  input {
    border: 1px solid rgb(177, 177, 177);
    padding: 0.4rem 1rem;
    border-radius: 5px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  margin: 20px 0;
}
.closeModalPopup {
  padding: 10px 25px;
  background-color: rgb(184, 100, 100);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.closeModalPopup:hover {
  background-color: rgb(195, 120, 100);
  color: white;
}

.successModalPopup {
  transition: 0.1s ease-in-out;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  background-color: rgb(73, 182, 73);
}

.successModalPopup:hover {
  background-color: rgb(90, 202, 90);
}
