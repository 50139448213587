@use "../../assets/style/index" as *;

.leadListItem {
  height: 70px !important;
  width: 100%;
  height: auto;
  min-height: 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border: 1px solid $border-stroke-color;
  background: white;
  word-wrap: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  color: grey;

  &:hover {
    background: #f5f5f5a4;
  }

  div {
    font-size: 11px;
    font-weight: 400;
    font-family: $poppins-font;
    a {
      border-bottom: 1px solid #58a2d6;
      color: #58a2d6;
    }
  }

  button {
    background-color: white;
    cursor: pointer;
  }
}
