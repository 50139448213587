@use "../../assets/style/index" as *;

/* Header Container */
.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  min-height: 50px;
  border: 1px solid $border-stroke-color;
  box-sizing: border-box;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    border-right: 1px solid $border-stroke-color;
  }

  .elements {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;

    /* Menü Stilleri */
    ul {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 90%;
      margin: 0;
      padding: 0;
      list-style: none;
      height: 100%;

      li {
        margin-left: 4%;
        height: 100%;

        a {
          color: $button-color;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: $secondary-color;
            opacity: 0.8;
          }
        }
      }

      .active {
        border-bottom: 2px solid $secondary-color;
      }

      .active a {
        color: $secondary-color;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          background: $secondary-color;
          bottom: 0;
          left: 0;
        }
      }
    }

    .buttonWrapper {
      height: 100%;
      display: flex;
      gap: 8px;
      margin-left: auto;
      align-items: center;

      button {
        background: transparent;
        width: auto;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $stroke-button;
        border-radius: 5px;
        transition: border-color 0.3s, fill 0.3s;

        margin-right: 10px;

        &:hover {
          border-color: $secondary-color;

          svg {
            fill: $secondary-color;
          }
        }

        path {
          stroke: $button-icon-color;
        }

        &:last-child path {
          stroke: $secondary-color;
        }
      }
    }
  }

  /* Hamburger Menü (Mobilde) */
  .menuToggle {
    display: none;

    @media (max-width: 768px) {
      display: block;
      float: right;
      cursor: pointer;
      padding: 10px;
      background-color: #333;
      color: white;
      text-align: center;
      font-size: 20px;
    }
  }
}

/* Mobil Menü Stilleri */
@media (max-width: 768px) {
  .header .elements {
    width: unset;
    height: unset;
  }
  .header .elements ul {
    display: none; // Mobilde varsayılan olarak gizlenir
    flex-direction: column; // Dikey liste
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    z-index: 1000;
    height: auto;

    li {
      margin: 0; // Mobilde kenar boşluğu yok
      padding: 15px 0;
      width: 100%;
      text-align: center;

      a {
        width: 100%;
      }
    }
    .adminButton {
      display: none;
    }
  }
  .header .elements .buttonWrapper {
    display: none;
  }

  .menuOpen {
    display: flex !important; // Menü açıldığında gösterilir
  }
}

/* Profil Pop-up ve Card Pop-up */
.cardPopupWrapper {
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}

.cardPopup {
  width: 300px;
  height: 280px;
  z-index: 150;
  background: #daa335;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 43px;

  .close {
    position: absolute;
    top: 0.4rem;
    right: 0.7rem;
    width: 12px;
    height: 12px;
    padding: 12px;
    border-radius: 6px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(255, 255, 255, 0.13);
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    margin-bottom: 80px;

    .profileImage {
      width: 70px;
      height: 70px;
      border-radius: 10px;
      margin-bottom: 1rem;
      background-color: #efc776;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 35px;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 10px;
    }

    .nameandsurname {
      font-size: 18px;
      margin: 2px 0;
    }

    .profileId {
      font-size: 15px;
      margin-top: 1rem;
    }
  }
}

.cardPopupBottom {
  height: 84px;
  width: 100%;
  background: #fff3e8;
  position: absolute;
  bottom: 0;
  border-radius: 0 4px 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .myAcoountButton,
  .outButton {
    box-sizing: border-box;
    width: 127px;
    height: 42px;
    cursor: pointer;
  }

  .outButton {
    margin-left: 0.5rem;
    background-color: white;
    color: #5e5e5e;
  }

  .myAcoountButton {
    background-color: #daa335;
    color: white;
    margin-right: 0.5rem;
  }
}

.shadowBox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.166);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 140;
}

.adminButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: 165px;
  font-size: 14px;
  cursor: pointer;
  color: #5e5e5e;
  font-weight: bold;

  svg {
    height: 22px;
    width: 22px;
    color: black;
  }

  &:hover {
    color: #daa335;

    svg {
      color: #daa335;
    }
  }
}
