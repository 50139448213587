@use "../../assets/style/index" as *;

.createBidContent {
  background: $info-background;
  width: 99vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 40px;
  .bidForm {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    width: 87%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-radius: 3px;

    .leftBidForm {
      margin-right: 1rem;
      @media (min-width: 1000px) {
        margin-right: 3rem;
      }
      @media (min-width: 1200px) {
        margin-right: 5rem;
      }
    }
    @media (max-width: 1000px) {
      width: 98%;
    }
    @media (max-width: 1200px) {
      width: 95%;
    }
    @media (max-width: 950px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
// ::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #daa335;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #daa335;
// }
