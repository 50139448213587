.innerContainer {
  width: 100%;
  display: flex;
  height: 100vh;

  .formcontent {
    width: 50%;
    height: 100%;
    background-color: #fff9f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 2vh;
      left: 14%;
    }

    h5 {
      font-size: 12px;
      position: absolute;
      bottom: 10px;
    }
    h1 {
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      color: #5e5e5e;
      font-size: 34px;
    }
    p {
      line-height: 20px;
      letter-spacing: 1%;
      font-size: 12px;
      font-weight: 400;
    }
    .forms {
      width: 45%;
      min-width: 250px;
      display: flex;
      flex-direction: column;
      margin-top: 4rem;

      .error {
        background-color: #e74c3c;
        font-size: 16px;
        color: white;
        border-radius: 3px;
        padding: 10px 10px;
        margin: 5px 3px;
      }

      .success {
        background-color: #2ecc71;
        font-size: 16px;
        color: white;
        border-radius: 3px;
        padding: 10px 10px;
        margin: 5px 3px;
      }

      h1,
      p {
        margin-bottom: 1rem;
      }
      label {
        margin-top: 1rem;
        width: 100%;

        h1 {
          font-size: 14px !important;
          margin-bottom: 1rem;
        }
        input {
          width: 100%;
          height: 35px;
          border: 1px solid rgb(199, 198, 198);
          border-radius: 3px;
          padding-left: 10px;
          box-sizing: border-box;
        }
      }
      button {
        width: 100%;
        background-color: #daa335;
        color: white;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        padding: 12px;
        border-radius: 3px;
        margin-top: 37px;
        cursor: pointer;
      }
    }
  }
  .banner {
    width: 50%;
    // background: url(../../assets/img/loginbanner.png) no-repeat;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    display: flex;
    input {
      margin-right: 3px;
      width: 10% !important;
      height: 15px !important;
    }
    h1 {
      font-size: 12px !important;
      font-weight: 400;
    }
  }
  a {
    width: 200px;
    font-size: 14px;
    color: #daa335;
  }
}

.loginLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  a {
    font-size: 14px;
    color: #daa335;
    float: right;
  }
}
